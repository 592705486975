import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { ACTIVE_NETWORK, NetworkDetails } from "src/constants";
import toast from "react-hot-toast";
import Web3 from "web3";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [open, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [totalValidator, setTotalValidator] = useState(100);
  const [account, setAccount] = useState("");
  const [chainId, setChainId] = useState("");
  const [userData] = useState({});

  const connectWallet = async (walletName, condition) => {
    try {
      console.log(`${walletName} connection initiated`);
      let provider;
      if (walletName === "metamask") {
        if (typeof window.ethereum !== "undefined") {
          provider = window.ethereum;
        }
      } else if (walletName === "qie") {
        provider = window.qie;
        if (condition) {
          const delay = 2000;
          const timeoutId = setTimeout(() => {
            if (typeof window.qie !== "undefined") {
              provider = window.qie;
              proceedWithWalletConnection(provider, walletName); // Proceed with connection
            }
          }, delay);
        }
      } else {
        console.error(`${walletName} wallet is not installed or accessible.`);
        return;
      }
      if (provider) {
        await proceedWithWalletConnection(provider, walletName);
      }
    } catch (error) {
      console.error(`Error connecting to ${walletName} wallet:`, error);
    }
  };

  const proceedWithWalletConnection = async (provider, walletName) => {
    try {
      const web3 = new Web3(provider);
      await provider.request({ method: "eth_requestAccounts" });
      const accounts = await web3.eth.getAccounts();
      const currentAccount = accounts[0];
      setAccount(currentAccount);
      const chainId = await web3.eth.getChainId();
      setChainId(chainId);
      window.localStorage.setItem("walletName", walletName);
      setOpen(false);
    } catch (error) {
      console.error("Error during wallet connection:", error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("walletName")) {
      connectWallet(localStorage.getItem("walletName"), true);
    }
  }, [localStorage.getItem("walletName")]);

  useEffect(() => {
    let provider;
    if (
      localStorage.getItem("walletName") === "metamask" &&
      typeof window.ethereum !== "undefined"
    ) {
      provider = window.ethereum;
    } else if (
      localStorage.getItem("walletName") === "qie" &&
      typeof window.qie !== "undefined"
    ) {
      provider = window.qie;
    }
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (provider) {
          swichNetworkHandler();
        }
      }
    } // eslint-disable-next-line
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      toast.error(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const addNetworkHandler = async () => {
    try {
      let provider;
      if (
        localStorage.getItem("walletName") === "metamask" &&
        typeof window.ethereum !== "undefined"
      ) {
        provider = window.ethereum;
      } else if (
        localStorage.getItem("walletName") === "qie" &&
        typeof window.qie !== "undefined"
      ) {
        provider = window.qie;
      }
      await provider.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.error(error.message);
    }
  };

  const disconnectWallet = () => {
    try {
      setAccount("");
      setChainId("");
      const connectedWallet = window.localStorage.getItem("walletName");
      window.localStorage.removeItem("walletName");
      if (
        connectedWallet === "metamask" &&
        typeof window.ethereum !== "undefined"
      ) {
        if (window.ethereum.removeListener) {
          window.ethereum.removeListener(
            "accountsChanged",
            handleAccountsChanged
          );
          window.ethereum.removeListener("chainChanged", handleChainChanged);
        }
      } else if (
        connectedWallet === "qie" &&
        typeof window.qie !== "undefined"
      ) {
        if (window.qie.removeListener) {
          window.qie.removeListener("accountsChanged", handleAccountsChanged);
          window.qie.removeListener("chainChanged", handleChainChanged);
        }
      } else {
        console.log("No wallet is currently connected.");
      }
    } catch (error) {
      console.error("Error disconnecting wallet:", error);
    }
  };

  const handleAccountsChanged = (accounts) => {
    console.log("Accounts changed:", accounts);
  };

  const handleChainChanged = (chainId) => {
    console.log("Chain changed:", chainId);
  };

  let data = {
    userLoggedIn: isLogin,
    userData,
    account,
    open,
    setOpen: (e) => setOpen(e),
    totalValidator,
    setTotalValidator,
    isCorrectNetwork: chainId === ACTIVE_NETWORK ? true : false,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    connectWallet: connectWallet,
    disconnectWallet: disconnectWallet,
    swichNetworkHandler: () => swichNetworkHandler(),
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
