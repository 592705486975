import React, { useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Web3 from "web3";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(2),
    width: "80%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const ConnectWalletModal = ({ open, onClose }) => {
  const classes = useStyles();
  const { setAccount, setChainId, connectWallet } = useContext(AuthContext);

  const handleMetaMaskConnect = async () => {
    console.log("MetaMask connection initiated");
    // Add your MetaMask connection logic here
    if (typeof window.ethereum !== "undefined") {
      const web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.request({ method: "eth_requestAccounts" });

        // Get the current account address
        const accounts = await web3.eth.getAccounts();
        const currentAccount = accounts[0];
        setAccount(currentAccount);
        onClose();
        console.log("Connected Account: ", currentAccount);

        // Get the chain ID
        const chainId = await web3.eth.getChainId();
        setChainId(chainId);
        console.log("Connected Chain ID: ", chainId);

        // Get the library
        //   const library = new Web3(window.qie);
        // setLibrary(library);
        // You can now interact with the blockchain, for example, by sending transactions
      } catch (error) {
        console.error(
          "User denied wallet connection or error occurred:",
          error
        );
      }
    }
  };

  const handleQIEWalletConnect = async () => {
    console.log("QIE Wallet connection initiated");
    // Add your QIE Wallet connection logic here
    if (typeof window.qie !== "undefined") {
      const web3 = new Web3(window.qie);
      try {
        // Request account access
        await window.qie.request({ method: "eth_requestAccounts" });

        // Get the current account address
        const accounts = await web3.eth.getAccounts();
        const currentAccount = accounts[0];
        setAccount(currentAccount);
        console.log("Connected Account: ", currentAccount);
        // setAccount(currentAccount);
        // Get the chain ID
        const chainId = await web3.eth.getChainId();
        setChainId(chainId);
        console.log("Connected Chain ID: ", chainId);
        onClose();
        // Get the library
        //   const library = new Web3(window.qie);
        // setLibrary(library);
        // You can now interact with the blockchain, for example, by sending transactions
      } catch (error) {
        console.error(
          "User denied wallet connection or error occurred:",
          error
        );
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="connect-wallet-dialog-title"
      className={classes.dialog}
    >
      <DialogTitle id="connect-wallet-dialog-title">
        <Typography variant="h6">Connect Wallet</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Please select a wallet to connect:
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => connectWallet("metamask")}
          startIcon={
            <img
              src="/images/MetaMask.webp"
              alt="MetaMask wallet"
              style={{ height: "20px", width: "20px" }}
            />
          }
        >
          Connect with MetaMask
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => connectWallet("qie")}
          startIcon={
            <img
              src="/images/qie-wallet.png"
              alt="qie wallet"
              style={{ height: "20px", width: "20px" }}
            />
          }
        >
          Connect with QIE Wallet
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConnectWalletModal;
